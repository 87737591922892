const HeartIcon = () => (
  <svg
    width={58}
    height={58}
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons">
      <path
        id="Vector"
        d="M50.3628 11.1408C49.1285 9.90591 47.6629 8.9263 46.0499 8.25794C44.4369 7.58958 42.708 7.24557 40.962 7.24557C39.216 7.24557 37.4871 7.58958 35.874 8.25794C34.261 8.9263 32.7955 9.90591 31.5611 11.1408L28.9995 13.7025L26.4378 11.1408C23.9446 8.64756 20.563 7.24687 17.037 7.24687C13.511 7.24687 10.1294 8.64756 7.63614 11.1408C5.14288 13.6341 3.74219 17.0157 3.74219 20.5417C3.74219 24.0676 5.14288 27.4492 7.63614 29.9425L10.1978 32.5042L28.9995 51.3058L47.8011 32.5042L50.3628 29.9425C51.5977 28.7082 52.5773 27.2426 53.2457 25.6296C53.914 24.0166 54.2581 22.2877 54.2581 20.5417C54.2581 18.7956 53.914 17.0667 53.2457 15.4537C52.5773 13.8407 51.5977 12.3751 50.3628 11.1408Z"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default HeartIcon;
