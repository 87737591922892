const TwitterIcon = () => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    alt="Твиттер"
  >
    <path
      d="M30.6663 3.99998C29.3895 4.90062 27.9758 5.58946 26.4797 6.03998C25.6767 5.11666 24.6095 4.46224 23.4224 4.16521C22.2353 3.86819 20.9857 3.94291 19.8424 4.37925C18.6992 4.81559 17.7176 5.59252 17.0303 6.60494C16.343 7.61736 15.9833 8.81643 15.9997 10.04V11.3733C13.6565 11.4341 11.3347 10.9144 9.24102 9.86057C7.14733 8.80675 5.34676 7.25149 3.99967 5.33331C3.99967 5.33331 -1.33366 17.3333 10.6663 22.6666C7.92038 24.5306 4.64922 25.4652 1.33301 25.3333C13.333 32 27.9997 25.3333 27.9997 9.99998C27.9984 9.62858 27.9627 9.25811 27.893 8.89331C29.2538 7.5513 30.2141 5.85693 30.6663 3.99998Z"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TwitterIcon;
