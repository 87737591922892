const TelegramIcon = () => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
  >
    <g id="icons">
      <path
        id="Vector"
        d="M29.446,3.463C29.326,3.358 29.179,3.289 29.022,3.262C28.865,3.235 28.703,3.252 28.555,3.31L3.223,13.224C2.915,13.344 2.654,13.562 2.48,13.843C2.306,14.124 2.228,14.455 2.257,14.784C2.287,15.114 2.422,15.425 2.644,15.671C2.865,15.916 3.161,16.084 3.485,16.148L10.25,17.476L10.25,25C10.25,25.349 10.355,25.69 10.55,25.979C10.745,26.268 11.022,26.492 11.346,26.622C11.669,26.753 12.024,26.784 12.365,26.711C12.707,26.638 13.018,26.465 13.26,26.214L16.59,22.76L21.788,27.316C22.105,27.597 22.514,27.752 22.938,27.753C23.123,27.752 23.307,27.723 23.483,27.666C23.771,27.575 24.031,27.41 24.236,27.187C24.441,26.963 24.584,26.691 24.65,26.395L29.728,4.321C29.763,4.166 29.756,4.004 29.706,3.853C29.657,3.702 29.567,3.567 29.446,3.463ZM3.756,14.671C3.752,14.658 3.752,14.644 3.756,14.631C3.762,14.627 3.768,14.624 3.775,14.621L23.739,6.806L10.826,16.056L3.775,14.676L3.756,14.671ZM12.18,25.171C12.146,25.207 12.101,25.232 12.053,25.242C12.004,25.253 11.954,25.249 11.908,25.23C11.862,25.212 11.822,25.18 11.794,25.139C11.766,25.098 11.751,25.05 11.75,25L11.75,18.514L15.461,21.764L12.18,25.171ZM23.189,26.056C23.18,26.099 23.159,26.138 23.13,26.169C23.1,26.201 23.063,26.224 23.021,26.236C22.979,26.251 22.934,26.254 22.89,26.246C22.846,26.237 22.805,26.216 22.771,26.186L12.205,16.918L27.875,5.688L23.189,26.056Z"
        style={{
          fillRule: "nonzero",
          strokeWidth: "0.4px",
        }}
      />
    </g>
  </svg>
);
export default TelegramIcon;
